import '../styles/main.scss';
import './acf-blocks/wp-lead-form';
import RedirectModalInit from './redirect-modal';
import BlogAudioInit from './blog-audio';
import LinkAppendsInit from './link-appends';
import FilterBar from './filter-bar';
import Menu from './menu';
import './relationships';
import PlayOnHover from './play-on-hover';
import VideoController from './video-controller';
import TabsPanel from './tabs-panel';

const init = () => {
  LinkAppendsInit();
  BlogAudioInit();
  RedirectModalInit();
  const menu = new Menu('.navbar', true);
  menu.asyncDropdown();

  const inlineVideosArr = document.querySelectorAll('.a-video-controller');
  inlineVideosArr.forEach((video) => {
    // Create a new instance of the VideoController class
    const instanciatedVideoController = new VideoController(video);
  });

  const tabsPanel = new TabsPanel(
    '.tabs-nav',
    '.tabs',
  );

  const playOnHoverBlogVideos = new PlayOnHover();

  if (document.querySelector('.filter-bar__menu')) {
    const filterBar = new FilterBar('.filter-bar__menu');
  }
};

window.addEventListener('load', init);

(function () {
  class RadioSwitcher {
    /**
 * Create a lead form instance.
 * @param {string} switcherId - The element ID of the wrapper for the panel options.
 */
    constructor(switcherId = 'radio-switcher') {
      this.switcherWrapper = document.getElementById(`${switcherId}`);
      if (this.switcherWrapper) this.panelOptions = this.switcherWrapper.querySelectorAll('input');

      if (this.panelOptions) {
        [...this.panelOptions].forEach((radio) => {
          radio.addEventListener('change', this.radioSwitch.bind(this));
        });
      }
    }

    /**
 * Toggles the visible panel based on the radio button selected.
 */
    radioSwitch(e) {
      const hidePanel = document.querySelector('.radio-toggle__panel:not(.hide)');
      const showPanel = document.querySelector(`[data-panel="${e.target.value}"]`);

      hidePanel.classList.add('hide');
      showPanel.classList.remove('hide');
    }
  }
  document.getElementById('radio-switcher') ? new RadioSwitcher() : null;
}());

export default init;
